/* eslint-disable react/prop-types */
import React from "react";
import dayjs from "dayjs";
import { DocumentCopy16Filled } from "@fluentui/react-icons";
import { handleSetScopeIdToCell } from "../../../utils/utils";
import AppTooltip from "../../../../reusableComponents/AppTooltip";

/*global */

const ListItem = (props) => {
  const { columns, file } = props;

  return (
    <>
      {columns.map((column) => {
        return column.key === "uploadDate" ? (
          <div key={file.id + column.key} className="table_cell_content">
            {column.key in file ? dayjs(file[column.key]).format("D/MM/YYYY") : column.value}
          </div>
        ) : (
          <div key={file.id + column.key} className="table_cell_content">
            {column.key in file ? file[column.key] : column.value}
          </div>
        );
      })}
      <div
        key={file.id + "copy"}
        className="table_cell_action"
        onClick={(e) => {
          e.stopPropagation();
          handleSetScopeIdToCell(file.scopeId);
        }}
      >
        <AppTooltip tipMessage="Copy ID" positioning={"above-end"}>
          <DocumentCopy16Filled />
        </AppTooltip>
      </div>
    </>
  );
};

export default ListItem;
