import axiosInstance from "./apiService";
/* global process*/
const fissionApi = process.env.FISSION_API;
const MELODY_IQ_OCR = process.env.MELODY_IQ_OCR;
const MELODY_IQ_CHAT_REST = process.env.MELODY_IQ_CHAT_REST;
export const askMelodyApi = async (scopeId, message) => {
  const { data } = await axiosInstance.get(`${fissionApi}/${MELODY_IQ_CHAT_REST}`, {
    params: {
      scopeId,
      message,
    },
  });
  return data;
};
export const docOcr = async (body) => {
  const { data } = await axiosInstance.post(`${fissionApi}/${MELODY_IQ_OCR}`, body);
  return data;
};

export const apiSetMnemonic = async (body) => {
  const { data } = await axiosInstance.post(`/mnemonic`, body);
  return data;
};

export const apiGetMnemonic = async (body) => {
  const { data } = await axiosInstance.post(`/mnemonic/find`, body);
  return data;
};
