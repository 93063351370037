import axiosInstance from "./apiService";

/*global*/

export const getFiles = async ({ pageNumber = 0, pageSize = 10, search }) => {
  const { data } = await axiosInstance.post(`/documents/by-type`, {
    classificationType: "agent-bank-notice",
    pageNumber: pageNumber,
    pageSize,
    fileName: search,
    mimeType: ["application/pdf"],
  });
  return data;
};

export const getFile = async (scopeId) => {
  const { data } = await axiosInstance.get(`/internal/file-details/by-id/${scopeId}`);
  return data.data;
};

export const getApplication = async (scopeId) => {
  try {
    const { data } = await axiosInstance.get(`/document-data/${scopeId}`, {
      params: {
        ontologyIri: encodeURI("http://www.cognaize.com/ontologies/Notices/AgentBank/"),
      },
    });
    return data.data;
  } catch (error) {
    return error;
  }
};
