import React, { useCallback, useEffect, useRef, useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";

import DocumentViewerFooter from "../DocumentViewerFooter";
import "./PdfViewer.style.scss";
import { cleanWorksheetId } from "../../../utils/utils";
import useTagDrawer from "../../../../hooks/useBoundingBoxDrawer";
import BoundingBox from "./BoundingBox";
import { useHistory, useLocation } from "react-router-dom";
import { getOrCreateWorkbookId, storeLineageData } from "../../../../utils";
const documentChannel = new BroadcastChannel("document-channel");

/*global console Excel window document BroadcastChannel setTimeout URLSearchParams*/

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

// eslint-disable-next-line react/prop-types
const PdfViewer = ({ src, scopeId, disabled = false }) => {
  const [numPages, setNumPages] = useState(0);
  const history = useHistory(); // Access history object for navigation
  const location = useLocation();
  const { activeCellCoords: activeCellFromRoute } = location.state || {};
  const [scale, setScale] = useState(1);
  const [activeSheet, setActiveSheet] = useState("");
  const [currentData, setCurrentData] = useState({});
  const [activeCellCoords, setActiveCellCoords] = useState(null);
  const containerRef = useRef(null);
  const queryParams = new URLSearchParams(location.search);
  const parsedPage = +queryParams.get("page") || 1;
  const pageNumber = numPages ? (parsedPage !== 1 && parsedPage > numPages ? 1 : parsedPage) : parsedPage;
  const drawerData = useTagDrawer({
    container: containerRef.current,
    onDrawEnd: (data) => {
      const { x_min_percent, y_min_percent, x_max_percent, y_max_percent } = data;
      if (
        x_min_percent &&
        y_min_percent &&
        x_max_percent &&
        y_max_percent &&
        x_min_percent !== x_max_percent &&
        y_min_percent !== y_max_percent
      ) {
        Excel.run({ delayForCellEdit: true }, async (context) => {
          const selectedRange = context.workbook.getSelectedRange();
          selectedRange.clear();
          await context.sync();
          selectedRange.formulas = [
            [`={${pageNumber}, ${x_min_percent},${y_min_percent},${x_max_percent},${y_max_percent}}`],
          ];
          await context.sync();
          data.reset();
        }).catch((error) => {
          console.log(error);
        });
      }
    },
    disabled,
  });
  const renderData = useCallback(() => {
    const data = [];
    Object.entries(currentData).forEach(([key, value]) => {
      if (value.lineage && value.lineage.page_number === pageNumber) {
        data.push(
          <BoundingBox
            key={value.scopeId}
            className={`cell-${key}`}
            active={key === activeCellCoords}
            onClick={async () => {
              await Excel.run(async (context) => {
                const sheet = context.workbook.worksheets.getActiveWorksheet();
                const range = sheet.getRange(key); // Change the range address as needed.
                range.select(); // This will activate the cell.
                await context.sync();
              });
              setActiveCellCoords(key);
            }}
            onDoubleClick={() => {
              const workbookId = getOrCreateWorkbookId();
              //missing lineage property to remove lineage from data
              storeLineageData({ workbookId, sheetId: activeSheet, scopeId: value.scopeId, cellReference: key });
              setActiveCellCoords(null);
            }}
            left={value.lineage.bounding_box[0] + "%"}
            top={value.lineage.bounding_box[1] + "%"}
            width={value.lineage.bounding_box[2] - value.lineage.bounding_box[0] + "%"}
            height={value.lineage.bounding_box[3] - value.lineage.bounding_box[1] + "%"}
          />
        );
      }
    });
    return data;
  }, [currentData, pageNumber, activeCellCoords, activeSheet]);
  useEffect(() => {
    setActiveCellCoords(activeCellFromRoute || null);
  }, [activeCellFromRoute]);
  useEffect(() => {
    documentChannel.onmessage = ({ data: message }) => {
      const { data } = message;

      if (message.type === "LINEAGE_CREATED") {
        setActiveCellCoords(data.cellCoordinate);
        onPageChanged(data.lineage.page_number);
      } else if (message.type === "SET_ACTIVE_CELL_COORDS") {
        setActiveCellCoords(data.cellCoordinate);
        if (data.page) {
          onPageChanged(data.page);
        }
      }
    };
    return () => {
      documentChannel.close();
    };
  }, []);
  useEffect(() => {
    const workBookId = getOrCreateWorkbookId();
    const removeListener = window.storeData.addListener((prop, oldValue, newValue) => {
      const workbook = window.storeData[workBookId] || {};
      const worksheet = workbook[activeSheet];
      worksheet && worksheet[scopeId] && setCurrentData(newValue[activeSheet][scopeId]);
    });

    return () => {
      removeListener();
    };
  }, [activeSheet]);
  useEffect(() => {
    const workBookId = getOrCreateWorkbookId();
    const storeData = window.storeData || {};
    const workbook = storeData[workBookId] || {};
    const worksheet = workbook[activeSheet];
    if (worksheet && worksheet[scopeId]) {
      setCurrentData(worksheet[scopeId]);
    } else {
      setCurrentData({});
    }
  }, [activeSheet]);
  useEffect(() => {
    const getActiveSheetName = async () => {
      try {
        await Excel.run(async (context) => {
          const activeSheet = context.workbook.worksheets.getActiveWorksheet();

          activeSheet.load("id");
          await context.sync(); // Sync the workbook state

          setActiveSheet(cleanWorksheetId(activeSheet.id));
        });
      } catch (error) {
        console.error("Error getting active sheet name: ", error);
      }
    };
    getActiveSheetName();
  }, []);
  useEffect(() => {
    let selectionEvent;

    Excel.run(async (context) => {
      const workbook = context.workbook;

      // Event listener for selection change
      selectionEvent = workbook.onSelectionChanged.add(async (eventArgs) => {
        const activeSheet = eventArgs.workbook.worksheets.getActiveWorksheet();
        activeSheet.load("id");
        await context.sync();

        console.log(`Current active sheet: ${activeSheet.id}`);

        setActiveSheet(cleanWorksheetId(activeSheet.id));
      });

      await context.sync(); // Sync the event listener
    }).catch((error) => {
      console.error(error);
    });

    // Cleanup on component unmount
    return () => {
      if (selectionEvent) {
        Excel.run({ delayForCellEdit: true }, async (context) => {
          selectionEvent.remove(); // Remove the event listener
          await context.sync();
        }).catch((error) => {
          console.error(error);
        });
      }
    };
  }, []);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }
  const onPageChanged = (page) => {
    queryParams.set("page", page); // Update the 'page' query param
    history.push(`${location.pathname}?${queryParams.toString()}`); //
  };
  const onScaleChanged = (newScale) => {
    setScale(newScale);
  };
  const { x_min, y_min, height, width } = drawerData;
  return (
    <>
      <div>
        <Document
          file={src}
          onLoadSuccess={onDocumentLoadSuccess}
          onLoadError={(error) => console.log("Error while loading document! " + error.message)}
        >
          <div className="PDFViewerContainer">
            <div ref={containerRef} className="PDFViewerPageContainer">
              {renderData()}
              {!!(x_min && y_min && height && width) && (
                <BoundingBox key={"drawer_box"} left={x_min} top={y_min} height={height} width={width} />
              )}
              <Page
                onRenderSuccess={() => {
                  if (activeCellCoords) {
                    setTimeout(() => {
                      const element = document.querySelector(`.cell-${activeCellCoords}`);
                      if (element) {
                        element.scrollIntoView({ behavior: "smooth", block: "center", inline: "center" });
                      }
                    });
                  }
                }}
                pageNumber={pageNumber}
                renderAnnotationLayer={false}
                renderTextLayer={false}
                scale={scale}
              />
            </div>
          </div>
        </Document>
      </div>

      {!!+numPages && (
        <DocumentViewerFooter
          scaleEnabled={true}
          pageCount={numPages}
          onPageChanged={onPageChanged}
          onScaleChanged={onScaleChanged}
          scale={scale}
          pageNumber={pageNumber}
        />
      )}
    </>
  );
};

export default PdfViewer;
